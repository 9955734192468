@import '../../../../styles/variables.module.scss';

.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3000;

  @media screen and (max-width: 768px) {
    z-index: 5000;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
  }
}

.wrapperHidden {
  @media screen and (max-width: 480px) {
    z-index: 1000;
    transform: translate3d(-100%, 0px, 0px);
  }
}

.wrapperIframeMode {
  & .logoBox,
  & .menuContent {
    display: none;
  }

  & .headerWrapper {
    width: 60px;
  }
}

.headerWrapper {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3000;
  display: flex;
  width: 400px;
  background-color: white;
  transition: all .5s ease;
  transform: translate3d(0%, 0px, 0px);
  box-shadow: 4px 1px 4px rgba(195, 195, 195, 0.2), 0 -1px 0px rgba(0, 0, 0, 0.2);

  @media screen and (max-width: 768px) {
    z-index: 5000;
  }

  @media screen and (max-width: 480px) {
    width: 100%;
  }
}

.headerWrapperHidden {
  transform: translate3d(-100%, 0px, 0px);
}

.logoBox {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 5000;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 340px;
  height: 70px;
  background-color: white;
  transition: all .5s ease;
  transform: translate3d(60px, 0, 0);
  border-bottom-right-radius: 6px;

  & img {
    display: inline-block;
    max-width: 180px;
    height: auto;

    @media screen and (max-width: 480px) {
      max-width: 160px;
    }
  }

  @media screen and (max-width: 480px) {
    width: calc(100% - 60px);
  }
}

.logoBoxCollapsed {
  transform: translate3d(0%, 0px, 0px);
  box-shadow: 3px 3px 4px rgba(195, 195, 195, 0.2), 0 -1px 0px rgba(0, 0, 0, 0.2);

  @media screen and (max-width: 480px) {
    width: 340px;
    transform: translate3d(100%, 0px, 0px);
  }
}

.versionTag {
  position: absolute;
  top: 25px;
  right: 32px;
  display: inline-block;
  padding: 2px 6px;
  color: white;
  font-size: 9px;
  font-weight: 700;
  background-color: #84caa0;
  border-radius: 6px;

  @media screen and (max-width: 480px) {
    right: 40px;
    font-size: 8px;
  }
}

.newTag,
.betaTag {
  position: absolute;
  top: 17px;
  right: 38px;
  display: inline-block;
  padding: 2px 4px;
  color: white;
  font-size: 7px;
  font-weight: 700;
  text-transform: uppercase;
  background-color: #E5412E;
  border-radius: 4px;

  @media screen and (max-width: 480px) {
    right: 44px;
    font-size: 7px;
  }
}

.betaTag {
  background-color: #f39c12 !important;
}

.menuBarListItemOldTag {
  position: absolute;
  top: 4px;
  right: 3px;
  display: inline-block;
  padding: 1px 4px;
  color: white;
  font-size: 7px;
  font-weight: 600;
  text-transform: uppercase;
  background-color: #16a085 !important;
  border-radius: 4px;

  @media screen and (max-width: 480px) {
    font-size: 6px;
  }
}

.menuBarListItemNewTag,
.menuBarListItemBetaTag {
  position: absolute;
  top: 4px;
  right: 3px;
  display: inline-block;
  padding: 1px 4px;
  color: white;
  font-size: 7px;
  font-weight: 600;
  text-transform: uppercase;
  background-color: #E5412E !important;
  border-radius: 4px;

  @media screen and (max-width: 480px) {
    font-size: 6px;
  }
}

.menuBarListItemBetaTag {
  background-color: #f39c12 !important;
}

.toggleButton {
  position: absolute;
  top: 50%;
  right: -16px;
  width: 32px;
  height: 32px;
  padding: 0;
  background-color: white;
  border-radius: 6px;
  border: none;
  box-shadow: 0 1px 3px rgba(150, 150, 150, 0.12), 0 1px 2px rgba(125, 125, 125, 0.24);
  transform: translateY(-50%);
  cursor: pointer;
  transition: all .5s ease;

  &:hover {
    & svg {
      fill: #8E8E8E;
    }
  }

  & svg {
    font-size: 26px;
    fill: #A2A2A2;
    transition: fill 200ms ease-in-out, transform .5s ease;
  }

  @media screen and (max-width: 480px) {
    right: 16px;
  }
}

.toggleButtonExpand {
  & svg {
    transform: rotate(-180deg);
  }

  @media screen and (max-width: 480px) {
    right: -16px;
  }
}

.menuBar {
  position: relative;
  z-index: 3000;
  width: 60px;
  background-color: white;
  border-right: 1px solid #EBEAEF;
}

.menuContent,
.linksContent {
  flex-direction: column;
  width: 340px;
  padding-bottom: 50px;
  padding-top: 70px;

  @media screen and (max-width: 480px) {
    width: calc(100% - 60px);
  }
}

.linksContent {
  position: absolute;
  top: 0;
  left: 60px;
  bottom: 0;
  background-color: white;
  z-index: 2000;
  padding-bottom: 0;
  transition: all .5s ease;
  transform: translateX(-340px);

  @media screen and (max-width: 480px) {
    transform: translateX(-100%);
  }
}

.linksContentIsVisible {
  transform: translateX(0px);
}

.linksContentIframeMode {
  padding-top: 0;
}

.navOpenButton {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30px;
  padding: 0;
  border: none;
  color: #777;
  background-color: #ECECEC;
  cursor: pointer;
  transition: all 200ms ease-in-out;

  &:hover {
    background-color: #E4E4E4;
  }
}

.toggleButtonAlone {
  @media screen and (max-width: 480px) {
    transform: translate3d(50px, 0px, 0px);
  }
}

.topContent {
  padding: 0 16px 16px;
}

.contentWrapper {
  position: relative;
  flex: 1;
  padding: 0 16px;
  overflow-y: scroll;
}

.logo {
  display: block;
  margin-bottom: 6px;
  text-align: center;

  & img {
    display: inline-block;
    max-width: 180px;
    height: auto;
  }
}

.tabContent {
  flex: 1;
}

.statisticsButtonWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 24px;
}

.bottomContent {
  position: absolute;
  bottom: 0;
  left: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 340px;
  background-color: #f7f7f7;
}

.navItemButtonBeta {
  position: relative;
}

.navItemButtonBetaAddon {
  position: absolute;
  top: 10px;
  right: 6px;
  display: inline-block;
  padding: 2px 4px;
  margin-right: 12px;
  text-transform: uppercase;
  font-size: 10px;
  color: white;
  background-color: #f39c12;
  border-radius: 2px;
}

.activeBlockIconBetaAddon {
  position: absolute;
  top: 10px;
  right: 6px;
  display: inline-block;
  padding: 2px 4px;
  margin-right: 12px;
  text-transform: uppercase;
  font-size: 10px;
  color: white;
  background-color: #f39c12;
  border-radius: 2px;

  @media screen and (max-width: 480px) {
    right: auto;
    left: 18px;
  }
}

.infrastructureClasses {
  margin-top: 8px;
  margin-bottom: 24px;
}

.menuBarList {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

.menuBarListItem {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 46px;
  background-color: white;
  transition: all 200ms ease-in-out;
  cursor: pointer;

  @media screen and (max-height: 840px) {
    height: 40px;
  }

  @media screen and (max-height: 700px) {
    height: 36px;
  }
}

.menuBarListItemLine {
  position: absolute;
  top: 0;
  left: 0;
  height: 46px;
  width: 0.15rem;
  border-radius: 0.15rem;
  background-color: white;
  transition: all 200ms ease-in-out;

  @media screen and (max-height: 840px) {
    height: 40px;
  }

  @media screen and (max-height: 700px) {
    height: 36px;
  }
}

.menuBarListItemButtonIcon {
  max-width: 24px;
}

.moduleIcon,
.moduleIconDegradation {
  width: 22px;
  height: 22px;
  fill: #A2A2A2;
  transition: all 200ms ease-in-out;

  @media screen and (max-height: 840px) {
    height: 20px;
    width: 20px;
  }

  @media screen and (max-height: 700px) {
    height: 18px;
    width: 18px;
  }
}

// .moduleIconDegradation {
//   @media screen and (max-height: 840px) {
//     position: relative;
//     top: -8px;
//   }
// }

.menuBarListItemCoverage {
  &:hover {
    & svg {
      fill: #1a9272;
    }
  }

  &IsActive {
    background-color: #1a9272;

    & span {
      background-color: #1a9272;
    }

    & svg {
      fill: #1a9272;
    }
  }
}

.menuBarListItemCoverageQuality {
  & svg {
    width: 31px;
    height: 31px;

    @media screen and (max-height: 840px) {
      width: 24px;
      height: 24px;
    }
  }

  &:hover {
    & svg {
      fill: #455a64;
    }
  }

  &IsActive {
    background-color: #455a64;

    & span {
      background-color: #455a64;
    }

    & svg {
      fill: #455a64;
    }
  }
}

.menuBarListItemTrajectories {
  &:hover {
    & svg {
      fill: #7b1fa2;
    }
  }

  &IsActive {
    background-color: #7b1fa2;

    & span {
      background-color: #7b1fa2;
    }

    & svg {
      fill: #7b1fa2;
    }
  }
}

.menuBarListItemQualityOfPastureData {
  &:hover {
    & svg {
      fill: #e6680f;
    }
  }

  &IsActive {
    background-color: #e6680f;

    & span {
      background-color: #e6680f;
    }

    & svg {
      fill: #e6680f;
    }
  }
}

.menuBarListItemDeforestation {
  &:hover {
    & svg {
      fill: #cd6133;
    }
  }

  &IsActive {
    background-color: #cd6133;

    & span {
      background-color: #cd6133;
    }

    & svg {
      fill: #cd6133;
    }
  }
}

.menuBarListItemRegeneration {
  &:hover {
    & svg {
      fill: #24a34e;
    }
  }

  &IsActive {
    background-color: #24a34e;

    & span {
      background-color: #24a34e;
    }

    & svg {
      fill: #24a34e;
    }
  }
}

.menuBarListItemInfrastructure {
  &:hover {
    & svg {
      fill: #434343;
    }
  }

  &IsActive {
    background-color: #434343;

    & span {
      background-color: #434343;
    }

    & svg {
      fill: #434343;
    }
  }
}

.menuBarListItemIrrigation {
  & svg {
    width: 28px;
    height: 28px;
  }

  &:hover {
    & svg {
      fill: #a606c3;
    }
  }

  &IsActive {
    background-color: #a606c3;

    & span {
      background-color: #a606c3;
    }

    & svg {
      fill: #a606c3;
    }
  }
}

.menuBarListItemFire {
  &:hover {
    & svg {
      fill: #E5412E;
    }
  }

  &IsActive {
    background-color: #E5412E;

    & span {
      background-color: #E5412E;
    }

    & svg {
      fill: #E5412E;
    }
  }
}

.menuBarListItemMining {
  &:hover {
    & svg {
      fill: #F39C12;
    }
  }

  &IsActive {
    background-color: #F39C12;

    & span {
      background-color: #F39C12;
    }

    & svg {
      fill: #F39C12;
    }
  }
}

.menuBarListItemWater {
  &:hover {
    & svg {
      fill: #2691D9;
    }
  }

  &IsActive {
    background-color: #2691D9;

    & span {
      background-color: #2691D9;
    }

    & svg {
      fill: #2691D9;
    }
  }
}

.menuBarListItemSentinel {
  &:hover {
    & svg {
      fill: #1f2942;
    }
  }

  &IsActive {
    background-color: #1f2942;

    & span {
      background-color: #1f2942;
    }

    & svg {
      fill: #1f2942;
    }
  }
}

.menuBarListItemSoil {
  &:hover {
    & svg {
      fill: #886956;
    }
  }

  &IsActive {
    background-color: #886956;

    & span {
      background-color: #886956;
    }

    & svg {
      fill: #886956;
    }
  }
}

.menuBarListItemUrban {
  &:hover {
    & svg {
      fill: #F92D00;
    }
  }

  &IsActive {
    background-color: #F92D00;

    & span {
      background-color: #F92D00;
    }

    & svg {
      fill: #F92D00;
    }
  }
}

.menuBarListItemDegradation {
  &:hover {
    & svg {
      fill: #936327;
    }
  }

  &IsActive {
    background-color: #936327;

    & span {
      background-color: #936327;
    }

    & svg {
      fill: #936327;
    }
  }
}

.menuBarListItemCoralReef {
  &:hover {
    & svg {
      fill: #006F9A;
    }
  }

  &IsActive {
    background-color: #006F9A;

    & span {
      background-color: #006F9A;
    }

    & svg {
      fill: #006F9A;
    }
  }
}

.menuBarBottomContent {
  position: absolute;
  bottom: 0;
  left: 0;
}

.menuButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 59px;
  height: 50px;
  padding: 0;
  border: none;
  background-color: white;
  cursor: pointer;
  transition: all 200ms ease-in-out;

  & svg {
    font-size: 24px;
    fill: #A2A2A2;
    transition: all 200ms ease-in-out;
  }

  &:hover {
    & svg {
      fill: #8E8E8E;
    }
  }

  @media screen and (max-height: 840px) {
    height: 40px;

    & svg {
      font-size: 20px;
    }
  }
}

.translateButton {
  display: inline-flex;
  align-items: center;
  width: 100%;
  padding: 4px;
  background-color: white;
  border: none;
  border-top: 1px solid #EBEAEF;
  transition: all 200ms ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: #f7f7f7;
  }

  & > span {
    margin-left: 3px;
    font-size: 10px;
    font-weight: 500;
    color: #777;
  }

  & > svg {
    font-size: 14px;
    fill: #777;

    &:last-child {
      margin-left: auto;
      font-size: 16px;
    }
  }
}

.actionButton {
  color: #5c5c5c;

  &:first-child {
    margin-right: 16px;
  }

  & svg {
    margin-right: 6px;
    font-size: 20px;
  }
}

.fireModeContentWrapper {
  position: absolute;
  top: 70px;
  left: 60px;
  bottom: 16px;
  width: 340px;
  overflow-y: scroll;
  padding: 16px;
}

.fireModeContent {
  flex-direction: column;

  @media screen and (max-width: 480px) {
    width: calc(100% - 60px);
  }
}

.fireModeContentBox,
.fireModeContentBoxExtra {
  padding: 16px;
  margin-bottom: 16px;
  background: #F5F5F5;
  border-radius: 4px;
}

.fireModeContentBoxExtra {
  color: #856404;
  background-color: #fff3cd;

  & .fireModeContentBoxTextWrapper p {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.fireModeContentBoxTitle {
  margin-top: 0;
  font-size: 14px;
  font-weight: 500;
}

.fireModeContentBoxTextWrapper {
  p {
    font-size: 13px;
    line-height: 1.4;

    &:last-child {
      margin-bottom: 0;
    }
  }

  & > ul {
    padding-left: 16px;
    margin-top: 8px;
    margin-bottom: 0;

    & > li {
      display: block;
      margin-bottom: 6px;
      font-size: 12px;
    }
  }
}

.fireModeContentBoxActions {
  display: flex;
  justify-content: flex-end;
}

.customModeContentBoxButton {
  margin-top: 12px;
}

.fireModeContentBoxButton {
  margin-top: 12px;
  background-color: #e74c3c;

  &:hover {
    background-color: #e5412e;
  }
}

.infrastructureModeContentBoxButton {
  margin-top: 12px;
  background-color: #434343;

  &:hover {
    background-color: #555;
  }
}

.irrigationModeContentBoxButton {
  margin-top: 12px;
  background-color: #b731ce;

  &:hover {
    background-color: #ac2ec2;
  }
}

.deforestationModeContentBoxButton {
  margin-top: 12px;
  background-color: #cd6133;

  &:hover {
    background-color: #CD6130;
  }
}

.headerImageLogoSentinel {
  width: 180px !important;
  max-width: initial !important;
}

.headerImageLogoDegradation {
  width: 196px !important;
  max-width: 100% !important;
}

.degradationControls {
  margin-top: 42px;

  & > div {
    margin-bottom: 32px;

    &:last-child {
      margin-bottom: 12px;
    }
  }
}

.degradationControlsAction {
  display: flex;
  justify-content: flex-end;
  margin-top: -12px;
}
